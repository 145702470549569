<template>

<div class="col-12">
    <dl>
        <dt> <h2>デバイス名</h2></dt>
        <dd>{{device.DeviceID}}</dd>
        <dt><h4>アクセスキー</h4></dt>
        <dd> <span v-on:dblclick="onAccessKeyDblClick(device.SharedAccessKey)" >
                <input :type="inputType" id="password" class="input" style="cursor :pointer;" v-model="device.SharedAccessKey" disabled>
                </span>
                    <span style="cursor :pointer;" class="input-icon">
                      <font-awesome-icon :icon="iconType" @click="onAccessKeyClick" />
                    </span></dd>
        <dt><h4>状態</h4></dt>
        <dd><span :class='(device.State === "DeviceConnected")?"text-success":"text-danger"  ' > {{ device.State }} </span></dd>
    </dl>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { defineComponent } from "vue";

//import App from "@/device/esp32atomhubswitch.vue"; 

export default defineComponent({
  name: "Devices",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  device:{} 
  } },
  computed: {
    inputType: function () {
      return this.isSharedKeyChecked  ? "text" : "password";
    },
    iconType: function () {
      return this.isSharedKeyChecked  ? faEye:faEyeSlash ;//"eye-slash" : "eye";
    }
  },
  mounted : function(){

       this.device = this.$route.params;
   },
  methods: {
        onAccessKeyClick: function() {
            this.isSharedKeyChecked = !this.isSharedKeyChecked;
        },
        onAccessKeyDblClick: function (key){
           
            if (window.clipboardData) {
                window.clipboardData.setData('key', key);
               this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
           
            } else if (navigator.clipboard) {
                this.$emit('show-flash',{"message":key,"type": "primary"});
                navigator.clipboard.writeText(key)
                this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
            }
        }
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}