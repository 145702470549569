<template>

<div class="col-12"> 

<Device />

    <h3>リレーの制御</h3>
    <dl>
        <dt><label>Relay1</label></dt>
        <dd>
            <div class="text-center form-check form-switch form-switch-lg ">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="relay1.checked" @change="relayChanged('Relay1',relay1)">
                
            </div>
        </dd>
        <dt><label>Relay2</label></dt>
        <dd>
            <div class="text-center form-check form-switch form-switch-lg ">
            <input on  class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="relay2.checked" @change="relayChanged('Relay2' ,relay2)">
            </div>
        </dd>
     </dl>
</div>

</template>

<script>
import { defineComponent } from "vue";
import Device from "@/components/Device.vue"; 
import axios from 'axios';

export default defineComponent({
  name: "esp32atomhubswitch",
   components:  { Device } ,
   data : function() {return { 
     device : {},
     relay1:{checked: true},
      relay2:{checked: true},
  } } , 
  /*, computed: {
  },*/
  mounted : function(){

       this.device = this.$route.params;
       console.log( this.device);
       try{
            let setting = this.device["Setting"];
            console.log(setting);
            this.relay1.checked = setting["Relay1"];
            this.relay2.checked = setting["Relay2"];
       
       } catch(e){
           console.log(e);
       }

       
   },
  methods: {
        relayChanged : function( name ,relay){
    
            let param = {        
                
                "ServiceType" : "Relay" ,
                "RouteType" : "C2D" , 
                "ProductName" : "PaletteIoT" ,
                "DeviceID" : this.device.DeviceID ,
                "Message" :  { }
            };
            param.Message[name] =  relay.checked;
       
            axios.post(
            "https://iotcorefunction.azurewebsites.net/api/InsertDMMMessage?code=DZJ/tQTMYesdAAz3bUBOxPvDeWIBStwKUwwCv5a8462u9FDr41aRSg==" ,
            param ,
            {
                withCredentials: true ,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
             .then(function(response) {
                    console.log(response);
                return response.json();    
            }) .then(function(json) {
                console.log(json);
                this.devices  = json.devices;
                return true;
            }.bind(this));
      
               // relay1.checked = true;
      } 
    }
});

</script>

<style>

dd{
    display: inline-block;
}

</style>
